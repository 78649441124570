import { put, select, call } from 'redux-saga/effects';

// UTILITY
import bugsnagClient from '@/utility/bugsnagClient';
import { CustomError } from '@/utility/errors';

// LOCALIZATION
import { t } from '@/locale/i18n';

// STORE
import { NotifActions } from '@/store/actions';
import { AppSelectors, AuthSelectors, IpApiSelectors, PartnerSelectors } from '@/store/selectors';
import { getPartnerByDomainSaga, getPartnerSaga } from '@/store/saga/partner';
import { getMe } from '@/store/saga/auth';

export function* showErrorNotification(error) {
  const errorStatus = error.response?.status;

  if (!(errorStatus === 519 || errorStatus === 499)) {
    yield put(NotifActions.pushErrorNotificationAction(error));
  }
}

export function* getUserData() {
  const isAuth = yield select(AuthSelectors.getIsAuth);
  const userData = yield select(AuthSelectors.getUser);
  const hasUserData = !!userData;
  let user = userData;

  if (isAuth && !hasUserData) {
    user = yield call(getMe);
  }

  return user;
}

export function* getPartnerData() {
  const isPartnerDomain = yield select(PartnerSelectors.getIsPartnerDomain);
  const partnerData = yield select(PartnerSelectors.getPartnerData);
  const partnerSlug = yield select(AppSelectors.getPartner);
  const hasPartnerData = !!Object.keys(partnerData).length;
  let partner = partnerData;

  if (!hasPartnerData) {
    if (isPartnerDomain && !hasPartnerData) {
      partner = yield call(getPartnerByDomainSaga);
    } else if (partnerSlug) {
      partner = yield call(getPartnerSaga, { payload: { slug: partnerSlug } });
    }
  }

  return partner;
}

export function* getPartnerSlug() {
  const isPartnerDomain = yield select(PartnerSelectors.getIsPartnerDomain);
  let partner = yield select(AppSelectors.getPartner);

  if (isPartnerDomain && !partner) {
    const partnerData = yield call(getPartnerByDomainSaga);

    partner = partnerData?.partner_slug || '';
  }

  return partner;
}

export function* getIPGeoData(withError = true) {
  try {
    const data = yield select(IpApiSelectors.getIpApiData);

    if (!data && withError) {
      throw new CustomError(t('notification.ipGeoMissing'));
    }

    return data;
  } catch (error) {
    bugsnagClient.notify(error);

    throw error;
  }
}

export default showErrorNotification;
